import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { TailSpin } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import { ApiService } from "../../Components/Services/apiServices";
import { DownloadTableExcel } from 'react-export-table-to-excel';

let apiServices = new ApiService();
function StatementList() {
  const [equitystatementList, setEquityStatementList] = useState([])
  const [deptstatementList, setDebtStatementList] = useState([])
  const [schemeData, setSchemeData] = useState([])
  const [summaryDetail, setSummaryDetail] = useState([])
  const [longTermWithIndex, setLongTermWithIndex] = useState([])
  const [longTermWithoutIndex, setLongTermWithoutIndex] = useState([])
  const [CGSRequestData, setCGSRequestData] = useState({})
  const tableRef = useRef(null);
  const [UserData, setUserData] = useState("")

  const [spinnerLoading, setSpinnerLoading] = useState(false)
  const [CGSSummary, setCGSSummary] = useState({'TotalEquityShortTerm':0,'TotalEquityLongTerm':0,'TotalDebtShortTerm':0,'TotalDebtShortTerm':0})
  const didMountRef = useRef(true)
  const { id } = useParams()
  const navigate = useNavigate()
  const rows = ["summary_quater_1", "summary_quater_2", "summary_quater_3", "summary_quater_4", "summary_quater_5", "summary_total"];
  const rowLabels = ["01/04 to 15/06", "16/06 to 15/09", "6/09 to 15/12", "16/12 to 15/03", "16/03 to 31/03", "Total"];
  const array = [2, 11, 23,52,23, 3, 4, 45, 32, 7, 6];

  const findSecondLargest = (arr) => {
    if (arr.length < 2) {
      return "Array must have at least two elements.";
    }
  
    // Sort the array in descending order
    const sortedArray = [...arr].sort((a, b) => b - a);
  console.log(sortedArray,"sortedArray")
    // Return the second largest unique element
    const uniqueArray = [...new Set(sortedArray)];
    console.log(uniqueArray)
    if (uniqueArray.length < 2) {
      return "No second largest element (all elements are the same).";
    }
  
    return "The second largest element is " + uniqueArray[1];
  };
  useEffect(() => {
    if (didMountRef.current) {
   
      console.log(findSecondLargest(array));
      statementListData()
      userdata()

    }
    didMountRef.current = false;
  }, []);
  const statementListData = () => {
    setSpinnerLoading(true)
    const dataString = {
      cgs_id: id
    }
    apiServices.CGSStatementListpostrequest(dataString).then(result => {
      if (result.data.status == 'success') {
        setSpinnerLoading(false)
        setEquityStatementList(result.data.equity)
        setDebtStatementList(result.data.debt)
        setSchemeData(result.data.scheme)
        setSummaryDetail(result.data.shortterm)
        setLongTermWithIndex(result.data.longtermwithindex)
        setLongTermWithoutIndex(result.data.longtermwithoutindex)
        setCGSRequestData(result.data.CGSStatementRequest)
        
        setCGSSummary(({
          'TotalEquityShortTerm': result.data.equity.reduce((sum, item) => sum + parseFloat(item.cgsd_short_term || 0), 0),
          'TotalEquityLongTerm': result.data.equity.reduce((sum, item) => sum + parseFloat(item.cgsd_long_term_without_index || 0), 0),
          'TotalDebtShortTerm': result.data.debt.reduce((sum, item) => sum + parseFloat(item.cgsd_short_term || 0), 0),
          'TotalDebtLongTerm': result.data.debt.reduce((sum, item) => sum + parseFloat(item.cgsd_long_term_without_index || 0), 0)
        }));

        const groupedData = Object.values(result.data.scheme.reduce((acc, curr) => {
          const schemeName = curr.cgsd_scheme_name;

          if (!acc[schemeName]) {
            acc[schemeName] = { key: schemeName, data: [] };
          }
          acc[schemeName].data.push(curr);

          return acc;
        }, {}));

        setSchemeData(groupedData)
      }
      else if (result.data.status === "error" && result.data.message === "Session expired") {
        navigate("/");
        setSpinnerLoading(false)
        localStorage.removeItem('AUTH_TOKEN');
      }
      else {
        setSpinnerLoading(false)
      }
    })
  }
  const userdata = () => {
    apiServices.bsegetuserdataPostRequest({}).then(result => {
      console.log(result)
      if (result.data.status == 'success') {
        setUserData(result.data.data)

      }
      else if (result.data.status == "error" && result.data.message == "Session expired") {
        localStorage.removeItem('AUTH_TOKEN');
        navigate("/")

      }
      else {
        alert(result.data.message)

      }
    })
  }
  return (
    <>

    <p></p>
      <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
        <div className='overlay-box'>
          <TailSpin
            color="#ffffff"
            visible={spinnerLoading}
          />
        </div>
      </div>
      <Header />
                <DownloadTableExcel filename="CGS_Statements" sheet="CGS_Statements" currentTableRef={tableRef.current} >
                  <div className="text-center mt-3">
                   <button className="btn btn-primary btn-sm"> Export excel </button>
                   </div>
                </DownloadTableExcel>


      <section className="sec-pad-sm"  ref={tableRef}>
        <div className="container" >
          <div  >
            {/* <div className="row g-3"  >
              <div className="col-lg-12">
                <div>
                  <a href="/dashboard" className="logo"><img src="https://app.goalstox.com/img/logo.png" style={{ width: "150px", color: "#fff" }}></img></a>
                </div>
              </div>
            </div> */}
     
            <h6 className="mb-3" style={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}>CAPITAL GAIN / LOSS REPORT ({CGSRequestData.cgs_start_financial_year} - {CGSRequestData.cgs_end_financial_year})</h6>
            <div className="row g-3" >
              <div className="col-lg-6">
                <div style={{padding: '10px', marginRight: '10px'}} >
                 <table style={{ borderCollapse: 'collapse', width: '100%' }}>
  <tbody>
    <tr>
      <td style={{ border: '1px solid #ddd', padding: '8px' }}>Name:</td>
      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{UserData.gs_fname} {UserData.gs_lname}</td>
    </tr>
    <tr>
      <td style={{ border: '1px solid #ddd', padding: '8px' }}>Address:</td>
      <td style={{ border: '1px solid #ddd', padding: '8px' }}>
        {UserData.gs_address1} {UserData.gs_address2}
      </td>
    </tr>
    <tr>
      <td style={{ border: '1px solid #ddd', padding: '8px' }}>City & PIN:</td>
      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{UserData.gs_city} {UserData.gs_pin}</td>
    </tr>
    <tr>
      <td style={{ border: '1px solid #ddd', padding: '8px' }}>PAN:</td>
      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{UserData.gs_pan_no}</td>
    </tr>
    <tr>
      <td style={{ border: '1px solid #ddd', padding: '8px' }}>Email:</td>
      <td style={{ border: '1px solid #ddd', padding: '8px' }}>{UserData.gs_email}</td>
    </tr>
    <tr>
      <td style={{ border: '1px solid #ddd', padding: '8px', }}>Mobile:</td>
      <td style={{ border: '1px solid #ddd', padding: '8px',textAlign:'left' }}>{UserData.gs_mobile}</td>
    </tr>
  </tbody>
</table>

                </div>
              </div>
              {/* <div className="col-lg-6">
                <div
                  style={{
                    border: '2px solid #000',
                    padding: '10px',
                  }}
                >
                  Content for the second column
                </div>
              </div> */}
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <h6 className="mb-3" style={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}>Summary Of Capital Gains</h6>
                <table style={{ width: "100%", borderCollapse: "collapse", border: "2px solid black" }}>
  <thead style={{ borderBottom: "1px solid black" }}>
    <tr >
      <th colSpan="2" style={{ border: "1px solid black", padding: "8px" }}>Equity</th>
      <th colSpan="2" style={{ border: "1px solid black", padding: "8px" }}>Debt</th>
    </tr>
  </thead>
  <tbody>
  <tr>
      <th style={{ border: "1px solid black", padding: "8px" }}>Short Term</th>
      <th style={{ border: "1px solid black", padding: "8px" }}>Long Term</th>
      <th style={{ border: "1px solid black", padding: "8px" }}>Short Term</th>
      <th style={{ border: "1px solid black", padding: "8px" }}>Long Term</th>
    </tr>
    <tr>
      <td style={{ border: "1px solid black", padding: "8px" }}>{CGSSummary && CGSSummary.TotalEquityShortTerm ? CGSSummary.TotalEquityShortTerm.toFixed(2) : 0}</td>
      <td style={{ border: "1px solid black", padding: "8px" }}>{CGSSummary && CGSSummary.TotalEquityLongTerm ? CGSSummary.TotalEquityLongTerm.toFixed(2) : 0}</td>
      <td style={{ border: "1px solid black", padding: "8px" }}>{CGSSummary && CGSSummary.TotalDebtShortTerm ? CGSSummary.TotalDebtShortTerm.toFixed(2) : 0}</td>
      <td style={{ border: "1px solid black", padding: "8px" }}>{CGSSummary && CGSSummary.TotalDebtLongTerm ? CGSSummary.TotalDebtLongTerm.toFixed(2) : 0}</td>
    </tr>
  </tbody>
</table>


              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <h6 className="mb-3" style={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}>Total Capital Gains</h6>
                <h6 className="mb-3" style={{ fontSize: "18px", fontWeight: "bold" }}>EQUITY FUND</h6>
                <table style={{ width: "100%", borderCollapse: "collapse", border: "2px solid black" }}>
  <thead style={{ borderBottom: "1px solid black" }}>
    <tr>
      <th style={{ border: "1px solid black", padding: "8px" }}>Scheme</th>
      <th style={{ border: "1px solid black", padding: "8px" }}>Folio</th>
      <th style={{ border: "1px solid black", padding: "8px" }}>Redemption date</th>
      <th style={{ border: "1px solid black", padding: "8px" }}>Units sold</th>
      <th style={{ border: "1px solid black", padding: "8px" }}>Sale amount</th>
      <th style={{ border: "1px solid black", padding: "8px" }}>LT Gain/ Loss (with index)</th>
      <th style={{ border: "1px solid black", padding: "8px" }}>LT Gain/ Loss (without index)</th>
      <th style={{ border: "1px solid black", padding: "8px" }}>ST Gain/Loss</th>
    </tr>
  </thead>
  <tbody>
    {equitystatementList.length > 0 ? (
      <>
        {equitystatementList.map((value, index) => (
          <tr key={index}>
            <td style={{ border: "1px solid black", padding: "8px", width: "300px" }}>{value.cgsd_scheme_name}</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>{value.cgsd_folio}</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>{value.cgsd_date}</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>{value.cgsd_units}</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>{value.cgsd_amount}</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>{value.cgsd_long_term_with_index}</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>{value.cgsd_long_term_without_index}</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>{value.cgsd_short_term}</td>
          </tr>
        ))}

        {/* Total Row */}
        <tr style={{ fontWeight: "bold", backgroundColor: "#f2f2f2" }}>
          <td style={{ textAlign: "center", border: "1px solid black", padding: "8px" }}>Total</td>
          <td style={{ textAlign: "right", border: "1px solid black", padding: "8px" }}></td>
          <td style={{ textAlign: "right", border: "1px solid black", padding: "8px" }}></td>
          <td style={{ textAlign: "right", border: "1px solid black", padding: "8px" }}></td>
          <td style={{ textAlign: "right", border: "1px solid black", padding: "8px" }}></td>
          <td style={{ border: "1px solid black", padding: "8px" }}>
            {equitystatementList.reduce((sum, item) => sum + parseFloat(item.cgsd_long_term_with_index || 0), 0)}
          </td>
          <td style={{ border: "1px solid black", padding: "8px" }}>
          {CGSSummary && CGSSummary.TotalEquityLongTerm ? CGSSummary.TotalEquityLongTerm.toFixed(2):0}
          </td>
          <td style={{ border: "1px solid black", padding: "8px" }}>
          {CGSSummary && CGSSummary.TotalEquityShortTerm ? CGSSummary.TotalEquityShortTerm.toFixed(2):0}
          </td>
        </tr>
      </>
    ) : (
      <tr>
        <td colSpan={8} style={{ textAlign: "center", border: "1px solid black", padding: "8px" }}>
          No Data Found
        </td>
      </tr>
    )}
  </tbody>
</table>


              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <h6 className="mb-3" style={{ fontSize: "18px", fontWeight: "bold" }}>DEBT FUND</h6>
                <table  style={{ width: "100%", borderCollapse: "collapse", border: "2px solid black" }}>
                  <thead style={{ borderBottom: "1px solid black" }}>

                    <tr>
                      <th scope="col" style={{ border: "1px solid black", padding: "8px" }}>Scheme</th>
                      <th scope="col" style={{ border: "1px solid black", padding: "8px" }}>Folio</th>
                      <th scope="col" style={{ border: "1px solid black", padding: "8px" }}>Redemption date</th>
                      <th scope="col" style={{ border: "1px solid black", padding: "8px" }}>Units sold</th>
                      <th scope="col" style={{ border: "1px solid black", padding: "8px" }}>Sale amount</th>
                      <th scope="col" style={{ border: "1px solid black", padding: "8px" }}>LT Gain/ Loss (with index)</th>
                      <th scope="col" style={{ border: "1px solid black", padding: "8px" }}>LT Gain/ Loss (without index)</th>
                      <th scope="col" style={{ border: "1px solid black", padding: "8px" }}>ST Gain/Loss</th>
                    </tr>
                  </thead>
                  <tbody>
    {deptstatementList.length > 0 ? (
      <>
        {deptstatementList.map((value, index) => (
          <tr key={index}>
            <td style={{ border: "1px solid black", padding: "8px", width: "300px" }}>{value.cgsd_scheme_name}</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>{value.cgsd_folio}</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>{value.cgsd_date}</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>{value.cgsd_units}</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>{value.cgsd_amount}</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>{value.cgsd_long_term_with_index}</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>{value.cgsd_long_term_without_index}</td>
            <td style={{ border: "1px solid black", padding: "8px" }}>{value.cgsd_short_term}</td>
          </tr>
        ))}

        {/* Total Row */}
        <tr style={{ fontWeight: "bold", backgroundColor: "#f2f2f2" }}>
          <td style={{ textAlign: "center", border: "1px solid black", padding: "8px" }}>Total</td>
          <td style={{ textAlign: "right", border: "1px solid black", padding: "8px" }}></td>
          <td style={{ textAlign: "right", border: "1px solid black", padding: "8px" }}></td>
          <td style={{ textAlign: "right", border: "1px solid black", padding: "8px" }}></td>
          <td style={{ textAlign: "right", border: "1px solid black", padding: "8px" }}></td>
          <td style={{ border: "1px solid black", padding: "8px" }}>
            {deptstatementList.reduce((sum, item) => sum + parseFloat(item.cgsd_long_term_with_index || 0), 0)}
          </td>
          <td style={{ border: "1px solid black", padding: "8px" }}>
          {CGSSummary && CGSSummary.TotalDebtLongTerm ? CGSSummary.TotalDebtLongTerm.toFixed(2):0}
          </td>
          <td style={{ border: "1px solid black", padding: "8px" }}>
          {CGSSummary && CGSSummary.TotalDebtShortTerm ? CGSSummary.TotalDebtShortTerm.toFixed(2):0}
          </td>
        </tr>
      </>
    ) : (
      <tr>
        <td colSpan={8} style={{ textAlign: "center", border: "1px solid black", padding: "8px" }}>
          No Data Found
        </td>
      </tr>
    )}
  </tbody>
                  
                </table>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12">
              <h6 className="mb-3" style={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}>Scheme-wise split</h6>
                {schemeData.map((group) => (
                  <div key={group.key}>
                 
                    <table style={{ width: "100%", borderCollapse: "collapse", border: "2px solid black",marginBottom:"35px" }}>
                      <thead  style={{ borderBottom: "1px solid black" }}>
                        <tr>
                          <th scope="col" style={{ border: "1px solid black", padding: "8px" }}>{group.key}</th>
                          <th scope="col" style={{ border: "1px solid black", padding: "8px" }}>Folio</th>
                          <th scope="col" style={{ border: "1px solid black", padding: "8px" }}>Redemption date</th>
                          <th scope="col" style={{ border: "1px solid black", padding: "8px" }}>Units sold</th>
                          <th scope="col" style={{ border: "1px solid black", padding: "8px" }}>Sale amount</th>
                          <th scope="col" style={{ border: "1px solid black", padding: "8px" }}>LT Gain/ Loss</th>
                          <th scope="col" style={{ border: "1px solid black", padding: "8px" }}>ST Gain/Loss</th>
                        </tr>
                       
                      </thead>
                      <tbody>
                        {group.data.map((value) => (
                          <tr key={value.cgsd_id} style={{ border: "1px solid black", padding: "8px" }}>
                            <td scope="row" style={{ width: '300px' }}></td>
                            <td style={{ border: "1px solid black", padding: "8px" }}>{value.cgsd_folio}</td>
                            <td style={{ border: "1px solid black", padding: "8px" }}>{value.cgsd_from} - {value.cgsd_to}</td>
                            <td style={{ border: "1px solid black", padding: "8px" }}>{value.cgsd_units}</td>
                            <td style={{ border: "1px solid black", padding: "8px" }}>{value.cgsd_amount}</td>
                            <td style={{ border: "1px solid black", padding: "8px" }}>{value.cgsd_lt_gain_loss}</td>
                            <td style={{ border: "1px solid black", padding: "8px" }}>{value.cgsd_st_gain_loss}</td>
                          </tr>
                       
                        ))}
                       
                      </tbody>
                    </table>

                    <table >
                    
                      
                      <tr>
                        
                        </tr>
                        <tr>
                        
                        </tr>
                        <tr>
                        
                        </tr>
                        
                       
                     
                       
                  
                    </table>
                  </div>
                ))}
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12">
              <h6 className="mb-3" style={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}>Quartelty short term capital gain split</h6>
              <h6 className="mb-3" style={{ fontSize: "18px", fontWeight: "bold" }}>Short term capital gain</h6>

                <table style={{ width: "100%", borderCollapse: "collapse", border: "2px solid black" }}>
                  <thead  style={{ borderBottom: "1px solid black" }}>
                    <tr>
                      <th scope="col">#</th>
                      {/* Render headers in a single loop */}
                      {summaryDetail.map((value, index) => (
                        <th scope="col" style={{ border: "1px solid black", padding: "8px" }} key={index}>
                          {value.summary_summary_of_capital_gains}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((rowKey, rowIndex) => (
                      <tr key={rowIndex}>
                        <td scope="row" style={{ border: "1px solid black", padding: "8px" }}>{rowLabels[rowIndex]}</td>
                        {summaryDetail.map((value, colIndex) => (
                          <td key={colIndex} style={{ border: "1px solid black", padding: "8px" }}>{value[rowKey]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>

              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">

                <h6 className="mb-3" style={{ fontSize: "18px", fontWeight: "bold" }}>Long term capital gain with indexation</h6>
                <table style={{ width: "100%", borderCollapse: "collapse", border: "2px solid black" }}>
                  <thead style={{ borderBottom: "1px solid black" }}>
                    <tr>
                      <th scope="col">#</th>
                      {/* Render headers in a single loop */}
                      {longTermWithIndex.map((value, index) => (
                        <th scope="col" key={index} style={{ border: "1px solid black", padding: "8px" }}>
                          {value.summary_summary_of_capital_gains}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((rowKey, rowIndex) => (
                      <tr key={rowIndex}>
                        <td scope="row" style={{ border: "1px solid black", padding: "8px" }}>{rowLabels[rowIndex]}</td>
                        {longTermWithIndex.map((value, colIndex) => (
                          <td key={colIndex} style={{ border: "1px solid black", padding: "8px" }}>{value[rowKey]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>

              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
              <h6 className="mb-3" style={{ fontSize: "18px", fontWeight: "bold" }}>Long term capital gain without indexation</h6>
                <table style={{ width: "100%", borderCollapse: "collapse", border: "2px solid black" }}>
                  <thead style={{ borderBottom: "1px solid black" }}>
                    <tr>
                      <th scope="col">#</th>
                      {/* Render headers in a single loop */}
                      {longTermWithoutIndex.map((value, index) => (
                        <th scope="col" key={index} style={{ border: "1px solid black", padding: "8px" }}>
                          {value.summary_summary_of_capital_gains}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map((rowKey, rowIndex) => (
                      <tr key={rowIndex} style={{ border: "1px solid black", padding: "8px" }}>
                        <td scope="row" style={{ border: "1px solid black", padding: "8px" }}>{rowLabels[rowIndex]}</td>
                        {longTermWithoutIndex.map((value, colIndex) => (
                          <td key={colIndex} style={{ border: "1px solid black", padding: "8px" }}>{value[rowKey]}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>

              </div>
            </div>
   
            <div className="row mt-4">
              <div className="col-12">

            <table>
            <tr colSpan="10"></tr>
            <tr colSpan="10"></tr>
            <tr colSpan="10"></tr>
            <tr colSpan="10"></tr>
              <tr>
            <td colSpan="10">
            <h5  style={{ fontSize: "16px", fontWeight: "bold" }}>Please Note:</h5>
              <p>
                Information displayed is purely based on data received from
                MFCentral (an investor services hub by KFintech & CAMS) and is
                subject to change.
              </p>
             
              <h6 style={{ fontSize: "16px", fontWeight: "bold" }}>Important Disclaimers:</h6>
              <ol>
                <li>
                  This Capital gain loss statement is for information purposes
                  only & should not be considered a base for determining any tax
                  liability.
                </li>
                <li>
                  This statement is generated based on data received from MF
                  Central (An investor service hub by KFintech and CAMS). You
                  are advised to consult your tax advisor.
                </li>
              </ol>
              <p>
                Goalstox Technology Private Limited (AMFI Registered Mutual
                Fund Distributor)
              </p>
            </td>
          </tr>

            </table>

              

              </div>
            </div>
          </div>
        </div>
      </section>



      <Footer />
    </>
  )
}
export default StatementList;