import React, { useEffect, useRef, useState } from "react";
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import { ApiService } from "../../Components/Services/apiServices";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import moment from "moment";
import { TailSpin } from "react-loader-spinner";
import { BrowserView, MobileView } from "react-device-detect";

let apiServices = new ApiService();
function PreviousRequestList() {
    const [cgsStatementData, setStatementData] = useState([])
    const [submitLoader, setSubmitLoader] = useState({})
    const [spinnerLoading, setSpinnerLoading] = useState(false)
    const didMountRef = useRef(true)
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            statementData()

        }
        didMountRef.current = false;
    }, []);
    const statementData = () => {
        setSpinnerLoading(true)
        apiServices.CGSStatementRequestListpostrequest({}).then(result => {
            if (result.data.status == 'success') {
                setStatementData(result.data.data)
                setSpinnerLoading(false)
            }
            else if (result.data.status === "error" && result.data.message === "Session expired") {
                navigate("/");
                localStorage.removeItem('AUTH_TOKEN');
                setSpinnerLoading(false)
            }
            else {
                setSpinnerLoading(false)
            }
        })
    }

    const getStatementDocument = (cgsId) => {

        setSubmitLoader(prevState => ({ ...prevState, [cgsId]: true }));
        const dataString = {
            cgs_id: cgsId
        }
        apiServices.getStatementDocumentpostrequest(dataString).then(result => {
            if (result.data.status == 'success') {
                statementData()
                setSubmitLoader(prevState => ({ ...prevState, [cgsId]: false }));

            }
            else if (result.data.status === "error" && result.data.message === "Session expired") {
                setSubmitLoader(prevState => ({ ...prevState, [cgsId]: false }));
                navigate("/");
                localStorage.removeItem('AUTH_TOKEN');
            }
            else {
                setSubmitLoader(prevState => ({ ...prevState, [cgsId]: false }));
                toast.error(result.data.message);
            }
        })
    }
    return (
        <>
            <div className={spinnerLoading == true ? 'parentDisable' : ''} width="100%">
                <div className='overlay-box'>
                    <TailSpin
                        color="#ffffff"
                        visible={spinnerLoading}
                    />
                </div>
            </div>
            <ToastContainer/>
            <Header />
            <BrowserView >
            <section className="sec-pad-sm">
                <div className="container">
                    <div className="row g-3">
                        <div className="col-lg-12">
                        {cgsStatementData && 
                            cgsStatementData.length > 0 ?
                            <div className="row g-2">
                            {cgsStatementData.map((item) => (
                                <div className="col-lg-12">

                                    <div className="invbox">
                                        <div className="invboxfund">

                                            <div className="invboxfund-details" style={{ marginLeft: '0' }}>
                                                <a href="#" className="tx-14">{item.cgs_client_ref_no}</a>

                                            </div>
                                        </div>
                                        <div className="invboxsec">
                                            <span className="tx-gray tx-12">Request ID</span>
                                            <span className="fw600">{item.cgs_req_id}</span>
                                        </div>
                                        <div className="invboxsec">
                                            <span className="tx-gray tx-12">Financial Year</span>
                                            <span className="fw600">{item.cgs_start_financial_year}-{item.cgs_end_financial_year}</span>
                                        </div>
                                        <div className="invboxsec">
                                            <span className="tx-gray tx-12">Request Date</span>
                                            <span className="fw600"> {moment(item.created_at).format('YYYY-MM-DD')}</span>
                                        </div>
                                        <div className="invboxsec">
                                            <span className="tx-gray tx-12">Status</span>
                                            {item.cgs_statement_status == 1 ?
                                                <span className="fw600"><span class="badge bg-warning-subtle text-warning">Requested</span></span>
                                                : <span className="fw600"><span class="badge bg-success-subtle text-success">Processed</span></span>}
                                        </div>
                                        <div className="invboxsec">
                                            {item.cgs_statement_status == 1 ?
                                                <span className="fw600" key={item.cgs_id}>
                                                    <button
                                                        className="btn btn-primary btn-sm float-end mt-2 me-2"
                                                        onClick={() => getStatementDocument(item.cgs_id)}
                                                        disabled={submitLoader[item.cgs_id]} // Disable button during loading
                                                    >
                                                        {submitLoader[item.cgs_id] ? (
                                                            <img src="/img/loder01.gif" width="60px" height="11px" alt="Loading..." />
                                                        ) : (
                                                            "Check Status"
                                                        )}
                                                    </button>
                                                </span>
                                                :(item.cgs_api_remark && item.cgs_api_remark !=null ? <span className="btn btn-outline-danger btn-sm" key={item.cgs_id}>
                                                   {item.cgs_api_remark}
                                                </span>: 
                                                <span className="fw600" key={item.cgs_id}>
                                                    <a href={"/statement-list/" + item.cgs_id} class="btn btn-outline-primary btn-sm">View Statement <i class="d-icon-angle-right"></i></a>
                                                 </span>)}
                                        </div>
                                    </div>
                                </div>))}
                            </div>
                            :
                            <div className="col-12">
                                <div className="noimg">
                                    <img src="/img/noitemavailable.webp" alt="img" />
                                    <h6>No Previous Request Found !</h6>
                                    <a href="/capital-gain-statement" className="btn btn-outline-primary btn-md mt-3">Start with a new request</a>
                                </div>
                            </div>
                        }
                        </div>
                    </div>
                </div>
            </section>
            </BrowserView>
            <MobileView>

                <div className="mobile-main">
                        {cgsStatementData && 
                            cgsStatementData.length > 0 ?
                            <>
                            {cgsStatementData.map((item) => (
                                <div className="mobileorder">
                                    <div className="mobileorderleft">
                                    <div className="tx-14 fw600 line18 mb-2">
                                    {item.cgs_client_ref_no} ({moment(item.created_at).format('YYYY-MM-DD')})
                                    </div>
                                     <ul className="mb-2">
                                        <li>Request Id : {item.cgs_req_id}</li>
                                        </ul>   
                                    {item.cgs_statement_status == 1 ?
                                    <span className="tx-16 fw600"><span class="badge bg-warning-subtle text-warning">Requested</span></span>
                                    : <span className="tx-16 fw600"><span class="badge bg-success-subtle text-success">Processed</span></span>}
                                    </div>
                                    <div className="mobileorderright">
                                    <div className="tx-16 fw600">{item.cgs_start_financial_year}-{item.cgs_end_financial_year}</div>
                               

                                    {item.cgs_statement_status == 1 ?
                                                <span className="fw600" key={item.cgs_id}>
                                                    <button
                                                        className="btn btn-primary btn-sm mt-2"
                                                        onClick={() => getStatementDocument(item.cgs_id)}
                                                        disabled={submitLoader[item.cgs_id]} // Disable button during loading
                                                    >
                                                        {submitLoader[item.cgs_id] ? (
                                                            <img src="/img/loder01.gif" width="60px" height="11px" alt="Loading..." />
                                                        ) : (
                                                            "Check Status"
                                                        )}
                                                    </button>
                                                </span>
                                                :(item.cgs_api_remark && item.cgs_api_remark !=null ? <button className="btn btn-outline-danger btn-sm mt-2" key={item.cgs_id}>
                                                   {item.cgs_api_remark}
                                                </button>: 
                                                <span className="fw600" key={item.cgs_id}>
                                                    <a href={"/statement-list/" + item.cgs_id} class="btn btn-outline-primary btn-sm mt-2">View Statement <i class="d-icon-angle-right"></i></a>
                                                 </span>)}
                                    </div>
                                </div>

                               ))}
                            </>
                            :
                            <div className="col-12">
                                <div className="noimg">
                                    <img src="/img/noitemavailable.webp" alt="img" />
                                    <h6>No Previous Request Found !</h6>
                                    <a href="/capital-gain-statement" className="btn btn-outline-primary btn-md mt-3">Start with a new request</a>
                                </div>
                            </div>
                        }
                        </div>
            </MobileView>
            <Footer />
        </>
    )
}
export default PreviousRequestList